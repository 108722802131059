import Logo from './images/logo.png';
import HeroPhones from './images/hero-phones.png';
import DownloadAndroid from './images/download-android.png';
import DownloadIos from './images/download-ios.png';
import Connect from './images/connect.png';
import Wallet from './images/wallet.png';
import Ad from './images/ad.png';
import Facebook from './images/facebook.png';
import Instagram from './images/instagram.png';
import X from './images/x.png';
import AboutPhone from './images/about-phone.png';
import WalletPhone from './images/wallet-phone.png';
import JobADPhone from './images/JobAdPhone.png';
import AppUserType from './images/app-user-type.png';
import LandingPhones from './images/landing_phones.png';
import PhotoHeroMock from './images/hero-mock-photo.png';
import PhotoSignInScreen from './images/SigninScreen.png';
import PhotoSignUpScreen from './images/SignUpScreen.png';
import PhotoJobSearchScreen from './images/jobSearchScreen.png';
import PhotoAboutUs from './images/about-us.png';
import ImageIconApp from './images/app-icon.png';
import PhotoEmployersHeroScreen from './images/employers-hero-image.png';
import PhotoEmployerSignUpScreen from './images/signup-employer-screen.png';
import PhotoEmployerSearchScreen from './images/Employer-search-screen.png';
import ImageBackground from './images/background-image.png';

import CrewLogo from './images/crew_logo.png';
import ImageSignUp from './images/signup-image.png';

// icons
import IconReview from './icons/review_icon.png';
import IconEmployee from './icons/employee_icon.png';
import IconEmployer from './icons/employer_icon.png';


export const ReviewIcon = IconReview;



// images
export const LogoImage = Logo;
export const HeroPhonesImage = HeroPhones;
export const DownloadAndroidImage = DownloadAndroid;
export const DownloadIosImage = DownloadIos;
export const ConnectImage = Connect;
export const WalletImage = Wallet;
export const AdImage = Ad;
export const FacebookImage = Facebook;
export const InstagramImage = Instagram;
export const XImage = X;
export const AboutPhoneImage = AboutPhone;
export const JobAdPhoneImage = JobADPhone;
export const WalletPhoneImage = WalletPhone;
export const AppUserTypeImage = AppUserType;
export const LandingPhonesImage = LandingPhones;
export const CrewLogoImage = CrewLogo;
export const SignUpImage = ImageSignUp;

export const AppIconImage = ImageIconApp;

export const EmployeeIcon = IconEmployee;
export const EmployerIcon = IconEmployer;
export const HeroMockPhoto = PhotoHeroMock;
export const SignInScreenPhoto = PhotoSignInScreen;
export const SignUpScreenPhoto = PhotoSignUpScreen;
export const JobSearchScreenPhoto = PhotoJobSearchScreen;
export const EmployersHeroScreenPhoto = PhotoEmployersHeroScreen;
export const EmployerSignUpScreenPhoto = PhotoEmployerSignUpScreen;
export const EmployerSearchScreenPhoto = PhotoEmployerSearchScreen;

export const BackgroundImage = ImageBackground;

export const AboutUsPhoto = PhotoAboutUs;