// @ts-nocheck
import React from "react";
import { CustomNavbar, Footer } from "../../components";

export const TermsAndConditionsScreen = () => {
    
    return (
        <>
          <div className="font-inter antialiased bg-dark bg-gray-900 text-gray-100 tracking-tight">
            <div className="flex flex-col min-h-screen overflow-hidden">
                <CustomNavbar />
                    <iframe
                        src="https://app.termly.io/policy-viewer/policy.html?policyUUID=def62612-facf-458c-946f-905196b57525"
                        title="Terms and Conditions"
                        className="w-full h-screen border-none"
                        style={{ border: 'none' }}
                    ></iframe>
                <Footer/>
            </div>
        </div>
        </>
    );
}