import React from 'react';
import { AppIconImage } from '../../assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons'; 

// Testimonials data
const testimonials = [
  {
    imgSrc: AppIconImage,
    name: 'Dave Kiranne',
    company: 'Kirrane Pipe & Civil',
    text: 'We were able to find staff instantly after downloading and searching through the talent pool, its a great help for our staffing issues. Highly recommended',
  },
  {
    imgSrc: AppIconImage,
    name: 'Evan Beirne',
    company: 'User',
    text: 'I managed to get 2 job offers after having this app downloaded its really good, i highly recommend it!',
  },
  {
    imgSrc: AppIconImage,
    name: 'Brian Moran',
    company: 'User',
    text: 'I found a job in minutes after my profile was created, employers started viewing my profile and called me!',
  },
  {
    imgSrc: AppIconImage,
    name: "Mathew Starrs",
    company: 'SMG Civil',
    text: 'This app is a dream to find people who are here in Australia and who are planning on moving to Australia with the travel mode. We can search for people who plan to arrive in Australia from abroad.',
  },
];

export const Testimonials = () => {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-transparent dark:border-gray-800">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h2 className="h1 font-red-hat-display mb-4 text-6xl">
                    Why Our Users <span className='text-red-500 font-bold'><FontAwesomeIcon icon={faHeart}/></span> Us
                </h2>
            </div>

          <div className="max-w-sm mx-auto grid gap-x-8 gap-y-12 sm:grid-cols-2 lg:gap-12 items-start sm:max-w-none md:max-w-2xl lg:max-w-none">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="text-center">
                <div className="relative inline-flex flex-col mb-4">
                  <img
                    className="rounded-full"
                    src={testimonial.imgSrc}
                    width="56"
                    height="56"
                    alt={`Testimonial ${index + 1}`}
                  />
                  <svg
                    className="absolute top-0 right-0 mt-1 -mr-5"
                    width="27"
                    height="12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="fill-current text-teal-500"
                      d="M2.785 5.334C2.538 5.5-.2 2.944.011 2.646.826 1.483 2.183.836 3.62.5 5.064.158 6.582.117 7.92-.02c.017-.002.098.153.088.166-1.763 2.018-3.223 3.836-5.221 5.188zm3.676 6.519c-.862.184-1.937-3.403-1.07-3.711 3.422-1.22 7.078-1.671 10.728-1.766 3.655-.096 7.304.162 10.866.32.044.002.06.177.018.187-6.938 1.634-13.691 3.504-20.542 4.97z"
                    />
                  </svg>
                </div>
                <blockquote className="text-xl text-gray-600 dark:text-gray-400">
                  “ {testimonial.text} “
                </blockquote>
                <div className="font-red-hat-display font-bold mt-2">
                  <cite className="not-italic">— {testimonial.name}</cite>,{' '}
                  <a
                    className="text-teal-500 transition duration-150 ease-in-out"
                    href="#0"
                  >
                    {testimonial.company}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
