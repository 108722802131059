import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import firebase from '../../firebase'; // Import the Firebase app
import { DownloadAndroidImage, DownloadIosImage,} from '../../assets';
import { addDoc, collection } from 'firebase/firestore';
import { CustomNavbar, Footer } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faPaperPlane, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'; 

export const ReviewScreen = () => {
    const { encodedData } = useParams<{ encodedData: any }>();
    const userId = atob(encodedData);

    const [formData, setFormData] = useState<any>({
        name: '',
        company: '',
        position: '',
        rating: 0,
        review: '',
    });
    
    const [ reviewSubmitted, setReviewSubmitted ] = useState<boolean>(false);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>('');

    const checkUserReview = () => {
        const reviewInfo = localStorage.getItem('reviewSubmitted');
        if (reviewInfo) {
            const { timestamp } = JSON.parse(reviewInfo);
            const now = new Date().getTime();
            if (now - timestamp < 3600000 ) { // 3600000 milliseconds = 1 hour
                setReviewSubmitted(true);
            }else{
                setReviewSubmitted(false);
            }
        }
    };

    useEffect(() => {
        checkUserReview();
    }, []);

    const handleSubmitReview = async (e:any) => {
        e.preventDefault();
        setLoading(true);
        try {
        const data = {
            ...formData,
            date: new Date(),
            user_id: userId
        };

        // Upload data to Firestore using the 'db' instance from the configuration
        const docRef = await addDoc(collection(firebase, 'Reviews'), data);

        if(docRef.id){
            setReviewSubmitted(true);

            const reviewInfo = {
                timestamp: new Date().getTime(),
            };

            localStorage.setItem('reviewSubmitted', JSON.stringify(reviewInfo));
        }

        // Reset the form or navigate to a success page
        } catch (error) {
        console.error('Error adding document: ', error);
        setError('Theres an issue with sending your review, please try again later');
        } finally {
            setLoading(true);
        }
    };


    const Responses = [
        "While the person was friendly, their work lacked attention to detail, which caused delays in the project.",
        "I was dissatisfied with the individual's performance, which was slow and unproductive.",
        "The individual's work lacked attention to detail, which resulted in errors and inconsistencies.",
        "The individual's work quality was good, but their unprofessional and lack of reliability left me dissatisfied.",
        "Not great, but positive attitude and willingness to improve. Would hire for simple tasks.",
        "Lacked expertise, but polite and easy to work with. Consider for less complex tasks.",
        "Inconsistent work, but available for questions and updates. Hire for smaller assignments.",
        "Shortcomings in work, but punctual and dependable. Would consider for simple tasks."
    ];

    const handleStarClick = (index: any) => {
        setFormData({...formData, rating: index + 1});
    };
    
    return (
        <>
            <div className="font-inter antialiased bg-dark text-gray-900 dark:bg-gray-900 dark:text-gray-100 tracking-tight">
                <div className="flex flex-col min-h-screen overflow-hidden">
                    <CustomNavbar />

                    <div className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true">
                        <svg className="absolute top-0 right-0 transform translate-x-1/2 -mr-16 dark:opacity-40" width="800" height="502" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="400" cy="102" r="400" fill="url(#heroglow_paint0_radial)" fillOpacity=".6" />
                        <circle cx="209" cy="289" r="170" fill="url(#heroglow_paint1_radial)" fillOpacity=".4" />
                        <defs>
                            <radialGradient id="heroglow_paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 149 251) scale(315.089)">
                                <stop stopColor="#3ABAB4" />
                                <stop offset="1" stopColor="#3ABAB4" stopOpacity=".01" />
                            </radialGradient>
                            <radialGradient id="heroglow_paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 -40 249) scale(133.913)">
                                <stop stopColor="#667EEA" />
                                <stop offset="1" stopColor="#667EEA" stopOpacity=".01" />
                            </radialGradient>
                        </defs>
                        </svg>
                    </div>

                    {reviewSubmitted ? (
                        <section className="relative h-screen flex items-center justify-center">
                            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                            <div className="pt-32 pb-12 md:pt-40 md:pb-20 text-center">
                                <div className="max-w-3xl mx-auto">
                                <h1 className="text-6xl font-red-hat-display mb-8">
                                    Thanks for Your <span className="font-bold text-teal-500">Review</span>! 🙌
                                </h1>
                                <p className="text-lg mb-8">
                                    Your feedback helps others find great opportunities. We appreciate your support in making our community stronger. If you love our app, why not download it here and keep the good vibes going?
                                </p>
                                <h1 className="text-3xl font-red-hat-display mb-8">
                                Download the <span className="font-bold text-teal-500">App</span> Today
                                </h1>
                                <div className='flex justify-center items-center'>
                                    <a className="mr-2" href="https://apps.apple.com/us/app/handyfind/id6469476759">
                                        {/* Download on App Store */}
                                        <img 
                                            className="relative pointer-events-none w-80"
                                            src={DownloadIosImage}
                                            alt="Download on the App Store"
                                        />
                                    </a>
                                    <a className="ml-2"
                                        href="https://play.google.com/store/apps/details?id=com.handyfindapp">
                                        {/* Download on Google Play Store */}
                                        <img 
                                            className="relative pointer-events-none w-80"
                                            src={DownloadAndroidImage}
                                            alt="Get it on Google Play"
                                        />
                                    </a>
                                </div>
                                </div>
                            </div>
                            </div>
                        </section>
                    ): (
                        <section className="relative flex items-center justify-center mb-[10%]">
                            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                                <div className="pt-32 pb-12 md:pt-40 md:pb-20 text-center">
                                    <div className="max-w-3xl mx-auto">
                                        <h1 className="text-6xl font-red-hat-display mb-8">
                                            Give a <span className="font-bold text-teal-500">Review</span>
                                        </h1>
                                        <p className="text-lg mb-8">
                                            Your review fuels an employee's reputation. A strong reputation
                                            attracts reliable and skilled professionals, benefiting all
                                            businesses in the search of top talent.
                                        </p>
                                        <form className='w-full' onSubmit={handleSubmitReview}>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                <div className="mb-4">
                                                    <label htmlFor="name" className="block text-left text-lg mb-2">Name</label>
                                                    <input
                                                        id="name"
                                                        type="text"
                                                        className="w-full px-2 py-2 border rounded-sm text-gray-900"
                                                        placeholder="Enter your name"
                                                        value={formData.name}
                                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="company" className="block text-left text-lg mb-2">Company</label>
                                                    <input
                                                        id="company"
                                                        type="text"
                                                        className="w-full px-2 py-2 border rounded-sm text-gray-900"
                                                        placeholder="Enter your company"
                                                        value={formData.company}
                                                        onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="position" className="block text-left text-lg mb-2">Position</label>
                                                    <input
                                                        id="position"
                                                        type="text"
                                                        className="w-full px-2 py-2 border rounded-sm text-gray-900"
                                                        placeholder="Enter your position"
                                                        value={formData.position}
                                                        onChange={(e) => setFormData({ ...formData, position: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-4 col-span-1 md:col-span-2">
                                                <label className="block text-left text-lg mb-2">Rating</label>
                                                <div className="flex justify-start items-center">
                                                    {[...Array(5)].map((_, index) => (
                                                        <FontAwesomeIcon icon={faStar} 
                                                            key={index}
                                                            onClick={() => handleStarClick(index)}
                                                            className={`cursor-pointer text-6xl ${
                                                                index < formData.rating ? "text-teal-500" : "text-gray-100"
                                                            }`}
                                                        />
                                                    ))}
                                                </div>
                                            </div>

                                            {formData.rating  !== 0 && formData.rating < 3  && (
                                                <div className='mt-3'>
                                                    <p className="text-gray-200 text-left mt-3">
                                                        <FontAwesomeIcon icon={faInfoCircle} className='mr-1'/> To maintain the quality and respectability of our platform, we limit the content of negative reviews. Please select the option below that best describes this person's behavior:
                                                    </p>
                                                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-6">
                                                        { Responses.map((review, key) => (
                                                            <div
                                                                key={key}
                                                                className="touchable-element bg-gray-900 hover:bg-teal-500"
                                                                onClick={() => setFormData({...formData, review: review})}
                                                                style={{
                                                                    // flex: 1, (You can remove this line)
                                                                    display: 'flex', // Use flexbox
                                                                    justifyContent: 'flex-start', // Center horizontally
                                                                    backgroundColor: formData.review === review ? "#14B8A6": 'rgba(0,0,0,0.5)',
                                                                    marginTop: 5,
                                                                    marginBottom: 25,
                                                                    padding: 20, 
                                                                    borderRadius: 5,
                                                                }}
                                                            >
                                                                <p style={{color: '#fff', margin: 0}}>{review}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}

                                            {formData.rating  !== 0 && formData.rating >= 3 && (
                                                    <div className="mb-4">
                                                        <label htmlFor="review" className="block text-left text-lg mb-2">Review</label>
                                                        <textarea
                                                            id="review"
                                                            className="w-full px-2 py-2 border rounded-sm text-gray-900"
                                                            placeholder="Tell us about them?"
                                                            value={formData.review}
                                                            onChange={(e) => setFormData({ ...formData, review: e.target.value })}
                                                            rows={5} // Adjust the number of rows as needed
                                                        />
                                                    </div>
                                            )}                                        
                                            <button
                                                disabled={formData.review === 0 || loading}
                                                type="submit"
                                                className="bg-teal-500 text-white px-6 py-3  my-4 mt-5 w-full rounded-full flex items-center justify-center hover:bg-teal-600 transition duration-300 ease-in-out"
                                            >
                                                {loading ? (
                                                    <FontAwesomeIcon icon={faSpinner} className='ml-3 text-2xl fa-spin' />
                                                ): (
                                                    <>
                                                        <span className='text-2xl'>Submit</span> <FontAwesomeIcon icon={faPaperPlane} className='ml-3 text-2xl' />
                                                    </>
                                                )}
                                            </button>
                                            { error !== '' && (
                                                <div className="mb-2 mt-2 bg-red-300 py-2 rounded hover:cursor-pointer" onClick={() => setError('')}>
                                                    <p className='text-red-500 text-center'>{error}</p>
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    <Footer/>
                </div>
            </div>
        </>   
    );
};
