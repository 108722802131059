import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'; 

const faqs = [
    {
      question: "How does YouCrew ensure the quality of job seekers?",
      answer: "YouCrew ensures quality by verifying profiles, providing comprehensive candidate information, and using a review system that allows employers to rate and review job seekers.",
    },
    {
      question: "How can Employers register and post job opportunities on YouCrew?",
      answer: "Employers can register by creating an account on YouCrew and then use the platform's tools to post job opportunities. The process is straightforward and guided to ensure you can quickly get your job openings live.",
    },
    {
      question: "Is there a fee for using YouCrew as an Employer?",
      answer: "Yes, YouCrew may charge fees for certain premium features and services. Basic job postings are often free, but additional features or promotions may incur a fee.",
    },
    {
      question: "Why do i have a limit of candidates i can call on the talent pool",
      answer: "Limits are applied to maintain the quality of interactions and prevent spam. You can remove limits by becoming a premium member",
    },
    {
      question: "Can Employers directly contact candidates on YouCrew?",
      answer: "Yes, employers can directly contact candidates as they have access to the all candidate profiles.",
    },
    {
      question: "What information should I include in a job posting on YouCrew?",
      answer: "Job postings should include details such as the job title, description, requirements, salary range, location, photos and any other relevant information that will help attract the right candidates.",
    },
    {
      question: "Can I use YouCrew to find workers for short-term projects?",
      answer: "Yes, YouCrew supports finding workers for both short-term and long-term projects. Employers can specify the type of employment in their job postings.",
    },
    {
      question: "How does the review system work for Employers on YouCrew?",
      answer: "The review system allows employers to leave feedback about job seekers they have worked with. This feedback helps other employers make informed decisions and maintains transparency on the platform.",
      },
    {
      question: "Are there any tools for managing multiple job postings on YouCrew?",
      answer: "Yes, YouCrew provides tools for managing multiple job postings, including dashboards to track applications, update job listings, and organize your hiring process more efficiently.",
    },
];
  
export const EmployerFAQ = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const toggleFAQ = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h1 font-red-hat-display mb-4 text-6xl">
            <span className='text-teal-500 font-bold'>FAQ</span>
            </h2>
            <p className="text-xl  text-gray-400">
              Find answers to common questions about how YouCrew can help you as an employer.
            </p>
          </div>

          {/* FAQ Items */}
          <div className="max-w-3xl mx-auto">
            {faqs.map((faq, index) => (
              <div key={index} className="mb-6">
                <button
                  onClick={() => toggleFAQ(index)}
                  className="w-full text-left text-lg font-medium  text-gray-200 bg-gray-800 p-4 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-teal-400 focus-visible:ring-opacity-75"
                >
                  {faq.question} {openIndex === index ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} className='ml-2'/>}
                </button>
                {openIndex === index && (
                  <div className="mt-2 p-4  bg-gray-700 text-gray-300 rounded-lg">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
