import React, { useState } from 'react';
import { LogoImage } from '../../assets';
import { Link } from 'react-router-dom';

export const CustomNavbar: React.FC = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <header className="relative w-full z-30">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex items-center justify-between h-20">
            {/* Site branding */}
            <div className="shrink-0 mr-5">
              {/* Logo */}
              <a className="block" href="/" aria-label="YouCrew">
                  <img className="w-[200px] h-10" src={LogoImage} alt="YouCrew logo"/>
              </a>
            </div>

            {/* Desktop navigation */}
            <nav className="hidden md:flex md:grow">
              {/* Desktop menu links */}
              <ul className="flex grow flex-wrap items-center font-medium">
                <li>
                  <Link className="text-white hover:text-teal-500 px-5 py-2 flex items-center transition duration-150 ease-in-out"  to="/">Job Seekers</Link>
                </li>
                <li>
                  <a className="text-white hover:text-teal-500 px-5 py-2 flex items-center transition duration-150 ease-in-out"  href="/employers">Employers</a>
                </li>
                <li>
                  <a className="text-white hover:text-teal-500 px-5 py-2 flex items-center transition duration-150 ease-in-out"  href="/#about">About Us</a>
                </li>
                <li>
                  <a className="text-white hover:text-teal-500 px-5 py-2 flex items-center transition duration-150 ease-in-out"  href="/#testimonials">Testimonials</a>
                </li>
              </ul>

              {/* Desktop CTA on the right */}
              <ul className="flex justify-end flex-wrap items-center">
                <li>
                  <a className="btn-sm text-white bg-teal-500 hover:bg-teal-600 ml-6 px-5 py-3 rounded text-lg text-bold" href="mailto:info@youcrew.com.au">Contact Us</a>
                </li>
              </ul>
            </nav>

            {/* Mobile menu */}
            <div className="inline-flex md:hidden">
              <button className="inline-flex items-center justify-center w-10 h-10" aria-label="Toggle menu" onClick={() => setExpanded(!expanded)}>
                <svg className="w-6 h-5" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1h14" stroke="#fff" strokeWidth="2" />
                  <path d="M1 8h14" stroke="#fff" strokeWidth="2" />
                  <path d="M1 15h14" stroke="#fff" strokeWidth="2" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu dropdown */}
        <nav className={`fixed inset-0 z-50 bg-dark  text-gray-400 transition-transform transform ${expanded ? 'translate-x-0' : '-translate-x-full'} ease-in-out duration-300`}>
          <div className="flex flex-col h-full">
            {/* Close Button */}
            <button
              className="absolute top-4 right-4  text-gray-400  hover:text-gray-200 text-6xl"
              onClick={() => setExpanded(false)}
            >
              &times;
            </button>

            {/* Menu Items */}
            <ul className="flex flex-col items-center justify-center h-full">
              <li className="mb-6">
                <Link onClick={() => setExpanded(false)} className="text-white  hover:text-white-500  text-red hover:text-white-100 px-5 py-2 flex items-center transition duration-150 ease-in-out" to="/#jobSeekers">Job Seekers</Link>
              </li>
              <li className="mb-6">
                <Link onClick={() => setExpanded(false)}  className="text-white  hover:text-white-500  text-red hover:text-white-100 px-5 py-2 flex items-center transition duration-150 ease-in-out" to="/employers">Employers</Link>
              </li>
              <li className="mb-6">
                <Link onClick={() => setExpanded(false)}  className="text-white  hover:text-white-500  text-red hover:text-white-100 px-5 py-2 flex items-center transition duration-150 ease-in-out" to="/#about">About</Link>
              </li>
              <li>
                <Link onClick={() => setExpanded(false)} className="text-white  hover:text-white-500  text-red hover:text-white-100 px-5 py-2 flex items-center transition duration-150 ease-in-out" to="/#testimonials">Testimonials</Link>
              </li>
              <li className='mt-4 mb-2'>
                <a className="btn-sm text-white bg-teal-500 hover:bg-teal-600 ml-6 px-10 py-2 rounded text-lg text-bold" href="mailto:info@youcrew.com.au">Contact Us</a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};
