import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HomeScreen, ReviewScreen, NotFoundScreen, TermsAndConditionsScreen, 
  PrivacyPolicyScreen, EULAScreen, DisclaimerScreen, AcceptableUsePolicyScreen,
} from './screens';
import { Employers } from './screens/EmployersScreen';

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsScreen />} />
          <Route path="/eula" element={<EULAScreen />} />
          <Route path="/disclaimer" element={<DisclaimerScreen />} />
          <Route path="/acceptable-use-policy" element={<AcceptableUsePolicyScreen />} />
          <Route path="/review/:encodedData" element={<ReviewScreen />} />
          <Route path='/employers' element={ <Employers /> }/>
          <Route path="*" element={<NotFoundScreen />} /> 
        </Routes>
    </Router>
  );
}

export default App;
