// @ts-nocheck
import React from "react";
import { CustomNavbar, Footer} from "../../components";

export const AcceptableUsePolicyScreen  = () => {
    
    return (
        <>
            <div className="font-inter antialiased bg-dark text-gray-900 dark:bg-gray-900 dark:text-gray-100 tracking-tight">
                <div className="flex flex-col min-h-screen overflow-hidden">
                    <CustomNavbar />
                        <iframe
                            src="https://app.termly.io/policy-viewer/policy.html?policyUUID=83d409be-214b-4127-afc3-9635118c24a5"
                            title="Terms and Conditions"
                            className="w-full h-screen border-none"
                            style={{ border: 'none' }}
                        ></iframe>
                    <Footer/>
                </div>
            </div>
        </>
    );
}