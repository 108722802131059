import React, { useEffect, useState } from "react";
import { CustomNavbar, EmployeeFAQ, Footer, Testimonials } from "../../components";
import { motion } from "framer-motion";
import { BackgroundImage, DownloadAndroidImage, DownloadIosImage, HeroMockPhoto, JobSearchScreenPhoto, SignInScreenPhoto, SignUpScreenPhoto } from "../../assets";

const Counter = ({ from, to }: any) => {
    const [count, setCount] = useState(from);
  
    useEffect(() => {
      const controls = setInterval(() => {
        setCount((prev: any) => {
          if (prev < to) return prev + 1;
          clearInterval(controls);
          return to;
        });
      }, 1500); // Adjust the speed of the counter here
  
      return () => clearInterval(controls);
    }, [to]);
  
    return <span>{count.toLocaleString()}</span>;
};

export const HomeScreen = () => {

    return(
        <>
            <div className="font-inter antialiased bg-dark bg-gray-900 text-gray-100 tracking-tight">
                <div className="flex flex-col min-h-screen overflow-hidden">
                    <CustomNavbar />
                    {/* <main className="grow"> */}
                        {/* Hero */}
                        <section className="relative pt-20 shadow"> {/* Added padding to the top */}
                            {/* Background image */}
                            <div className="absolute inset-0 h-128 pt-16 box-content -z-1">
                                <img 
                                    className="absolute inset-0 w-full h-full object-cover opacity-25" 
                                    src={BackgroundImage} 
                                    width="1440" 
                                    height="577" 
                                    alt="About" 
                                />
                                <div className="absolute inset-0 bg-gradient-to-t  from-gray-900" aria-hidden="true"></div>
                            </div>
                            <div className="relative max-w-6xl mx-auto px-4 py-4 sm:px-6">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                    <div className="order-1">
                                        <h1
                                            className="h1 text-6xl lg:text-6xl mb-4 font-bold text-white"
                                            data-aos="fade-down"
                                        >
                                            Find Jobs <span className='text-teal-500 font-bold'>Fast</span>
                                        </h1>
                                        <p className="text-gray-100 mt-4 text-lg">
                                            Redefining Job Search and Talent Discovery for Australia & New Zealand
                                        </p>
                                        <p className="text-gray-200 mt-4 text-lg">
                                            {/* YouCrew provides employers with a solution that simplifies the employment process, offering you a cost-effective and stress-free way to access a wide pool of skills and build your crew. With our platform, you can make choices efficiently, connect directly with candidates, save time, and bypass delays associated with traditional recruitment methods. We eliminate the middleman and utilise technology to make the process super accessible and straightforward. */}
                                        </p>

                                        <ul
                                            className="max-w-sm sm:max-w-md mx-auto md:max-w-none text-gray-300 mt-8 -mb-2"
                                            data-aos="fade-down"
                                            data-aos-delay="450"
                                        >
                                            <li className="flex items-center mb-2">
                                                <svg
                                                    className="w-3 h-3 fill-current text-teal-500 mr-2 shrink-0"
                                                    viewBox="0 0 12 12"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                                </svg>
                                                <span>Quick Set Up</span>
                                            </li>
                                            <li className="flex items-center mb-2">
                                                <svg
                                                    className="w-3 h-3 fill-current text-teal-500 mr-2 shrink-0"
                                                    viewBox="0 0 12 12"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                                </svg>
                                                <span>Hide From Current Employers</span>
                                            </li>
                                            <li className="flex items-center mb-2">
                                                <svg
                                                    className="w-3 h-3 fill-current text-teal-500 mr-2 shrink-0"
                                                    viewBox="0 0 12 12"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                                </svg>
                                                <span>Get Noticed</span>
                                            </li>
                                        </ul>
                                        <div className="flex mt-5 justify-start">
                                            <a className="mr-2" href="https://apps.apple.com/us/app/handyfind/id6469476759">
                                                {/* Download on App Store */}
                                                <img 
                                                    className="relative pointer-events-none"
                                                    src={DownloadIosImage}
                                                    alt="Download on the App Store"
                                                />
                                            </a>
                                            <a className="ml-2"
                                                href="https://play.google.com/store/apps/details?id=com.handyfindapp">
                                                {/* Download on Google Play Store */}
                                                <img 
                                                    className="relative pointer-events-none"
                                                    src={DownloadAndroidImage}
                                                    alt="Get it on Google Play"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="flex justify-start order-2 items-start my-5">
                                        <motion.div
                                            initial={{ x: 200 }}
                                            animate={{ x: 0 }}
                                            transition={{ duration: 0.8 }}
                                            className="relative w-full"
                                        >
                                            <div className="relative">
                                                    <svg
                                                        className="absolute items-start inset-10 pointer-events-none -z-1 opacity-40"
                                                        aria-hidden="true"
                                                        width="678"
                                                        height="634"
                                                        viewBox="0 0 678 634"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="240"
                                                            cy="394"
                                                            r="240"
                                                            fill="url(#piphoneill_paint0_radial)"
                                                            fillOpacity=".4"
                                                        />
                                                        <circle
                                                            cx="438"
                                                            cy="240"
                                                            r="240"
                                                            fill="url(#piphoneill_paint1_radial)"
                                                            fillOpacity=".6"
                                                        />
                                                        <defs>
                                                            <radialGradient
                                                                id="piphoneill_paint0_radial"
                                                                cx="0"
                                                                cy="0"
                                                                r="1"
                                                                gradientUnits="userSpaceOnUse"
                                                                gradientTransform="rotate(90 -77 317) scale(189.054)"
                                                            >
                                                                <stop stopColor="#667EEA" />
                                                                <stop offset="1" stopColor="#667EEA" stopOpacity=".01" />
                                                            </radialGradient>
                                                            <radialGradient
                                                                id="piphoneill_paint1_radial"
                                                                cx="0"
                                                                cy="0"
                                                                r="1"
                                                                gradientUnits="userSpaceOnUse"
                                                                gradientTransform="rotate(90 99 339) scale(189.054)"
                                                            >
                                                                <stop stopColor="#9F7AEA" />
                                                                <stop offset="1" stopColor="#9F7AEA" stopOpacity=".01" />
                                                            </radialGradient>
                                                        </defs>
                                                    </svg>
                                                <img
                                                    className="relative bottom-0 landing-image w-full h-auto"
                                                    alt="img"
                                                    src={HeroMockPhoto}
                                                />
                                                
                                            </div>
                                        </motion.div>
                                    </div>

                                </div>
                            </div>
                        </section>

                        {/* Download rates */}
                        <section className="relative pt-5">
                            {/* Background gradient (light version only) */}
                            <div
                                className="absolute bottom-0 left-0 right-0 h-128 bg-gradient-to- from-gray-100 to-white pointer-events-none -z-10 hidden"
                                aria-hidden="true"
                            ></div>
                            {/* End background gradient (light version only) */}
                            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                                <div className="pb-12 md:pb-20">
                                    <div
                                        className="grid grid-cols-1 gap-4 lg:gap-6 md:grid-cols-3 text-center"
                                        data-aos-id-stats
                                    >
                                        {/* 1st item */}
                                        <div
                                            className="bg-gray-800 py-8 px-1 shadow-2xl"
                                            data-aos="fade-down"
                                            data-aos-anchor="[data-aos-id-stats]"
                                        >
                                            <div className="font-red-hat-display text-3xl font-black tracking-tighter mb-1">
                                                <Counter from={5000} to={6000} /> <span className="font-light">+</span>
                                            </div>
                                            <div className="text-gray-400">Users</div>
                                        </div>
                                        {/* 2nd item */}
                                        <div
                                            className="bg-gray-800 py-8 px-1 shadow-2xl"
                                            data-aos="fade-down"
                                            data-aos-anchor="[data-aos-id-stats]"
                                            data-aos-delay="100"
                                        >
                                            <div className="font-red-hat-display text-3xl font-black tracking-tighter mb-1">
                                                <Counter from={983} to={1000} /> <span className="font-light">+</span>
                                            </div>
                                            <div className=" text-gray-400">Jobs</div>
                                        </div>
                                        {/* 3rd item */}
                                        <div
                                            className="bg-gray-800 py-8 px-1 shadow-2xl"
                                            data-aos="fade-down"
                                            data-aos-anchor="[data-aos-id-stats]"
                                            data-aos-delay="200"
                                        >
                                        <div className="font-red-hat-display text-3xl font-black tracking-tighter mb-1">
                                            <motion.span
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                transition={{ duration: 2 }}
                                            >
                                            17.4%
                                            </motion.span>
                                        </div>
                                        <div className="text-gray-400">Daily User Increase</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* About Us */}
                        <section className="relative">
                            <div id="about" className="about">
                                <svg
                                    className="absolute items-start inset-10 pointer-events-none -z-1 opacity-40 w-100"
                                    aria-hidden="true"
                                    width="700"
                                    height="700"
                                    viewBox="0 0 700 700"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <defs>
                                        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                                            <stop offset="0%" style={{ stopColor: "#FFDEE9", stopOpacity: 1 }} />
                                            <stop offset="100%" style={{ stopColor: "#B5FFFC", stopOpacity: 1 }} />
                                        </linearGradient>
                                        <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="100%">
                                            <stop offset="0%" style={{ stopColor: "#C6FFDD", stopOpacity: 1 }} />
                                            <stop offset="100%" style={{ stopColor: "#FBD786", stopOpacity: 1 }} />
                                        </linearGradient>
                                        <radialGradient id="grad3" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                                            <stop offset="0%" style={{ stopColor: "#667EEA", stopOpacity: 1 }} />
                                            <stop offset="100%" style={{ stopColor: "#667EEA", stopOpacity: 0.01 }} />
                                        </radialGradient>
                                    </defs>
                                    <ellipse cx="550" cy="150" rx="350" ry="150" fill="url(#grad3)" fillOpacity="0.4" />
                                </svg>
                                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                                    {/* Section header */}
                                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-0 pl-5 pr-5">
                                        <h2 className="h1 font-red-hat-display mb-4 text-6xl">
                                            Who are <span className='text-teal-500 font-bold'>we?</span>
                                        </h2>
                                        <p className="text-xl text-gray-400">
                                            Discover how YouCrew simplifies and speeds up the job search and hiring process. Our platform ensures you find and fill positions quickly, with efficient job matching and comprehensive profiles—making employment easier and faster for everyone.
                                        </p>
                                    </div>
                                </div>
                                <div className="max-w-6xl mx-auto sm:px-6 pl-5 pr-5">
                                    {/* Left Content */}
                                    <div className="pb-12 md:pb-20 md:p-20 w-[100%]">
                                        <p className="text-lg text-gray-400 mb-6">
                                            YouCrew emerged from a shared vision: to revolutionize how workers and employers connect, making the process simple, fair, and incredibly efficient. David, with a wealth of experience in various industries, and Aidan, a young and ambitious IT guru, joined forces to create an app that would redefine the job search landscape across sectors.
                                        </p>
                                        <p className="text-lg text-gray-400 mb-6 italic">
                                            “At YouCrew, we're not just offering an innovative app; we're fostering a community built by individuals who, like you, understand the challenges of the job search hustle. It's not just about making things easy; it's about tailoring an experience specifically for those in the industry.”
                                        </p>
                                        <p className="text-lg text-gray-400 mb-6">
                                            The heart of YouCrew lies in connecting workers and employers directly through the app. Workers can customize their work preferences, and employers gain the ability to contact high-quality employees directly. No more complicated recruitment processes: just a straightforward platform designed to meet the unique needs of the community.
                                        </p>
                                        <p className="text-lg text-gray-400">
                                            “Our purpose is deeply rooted in a commitment to level the playing field between employees and employers across all industries. We passionately believe that change isn't just necessary; it's imperative to address the current unfairness prevalent in the market. We see ourselves as agents of change, devoted to evolving the industry for the better. We are growing and can't wait for you to join us.”
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Testimonials */}
                        <div id="testimonials" className="testimonials">
                            <Testimonials />
                        </div>

                        {/* Process */}
                        <section className="relative" id="getStarted">
                            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                                <div className="py-12 md:py-20 border-t  border-gray-800">

                                    {/* Section header */}
                                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                                        <h2 className="h1 font-red-hat-display mb-4 text-6xl">
                                            Get Started in <span className='text-teal-500 font-bold'>3 Easy Steps</span>
                                        </h2>
                                        <p className="text-xl text-gray-400">
                                            Discover how YouCrew simplifies and accelerates your job search. Our platform enables employers to view your profile without you needing to apply for jobs, ensuring you get noticed faster.
                                        </p>
                                    </div>

                                    {/* Glow illustration */}
                                    <svg className="absolute left-1/2 transform -translate-x-1/2 mt-20 lg:mt-40 pointer-events-none -z-1 opacity-20 hidden md:block" aria-hidden="true" width="854" height="509" viewBox="0 0 854 509" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="39.386%" id="iphonesill__a">
                                                <stop stopColor="#667EEA" offset="0%" />
                                                <stop stopColor="#667EEA" stopOpacity="0" offset="100%" />
                                            </radialGradient>
                                            <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="39.386%" id="iphonesill__b">
                                                <stop stopColor="#9F7AEA" offset="0%" />
                                                <stop stopColor="#9F7AEA" stopOpacity="0" offset="100%" />
                                            </radialGradient>
                                        </defs>
                                        <g transform="translate(-64 -64)" fill="none" fillRule="evenodd">
                                            <circle fillOpacity=".64" fill="url(#iphonesill__a)" cx="300" cy="300" r="300" />
                                            <circle fillOpacity=".72" fill="url(#iphonesill__b)" cx="729" cy="384" r="240" />
                                        </g>
                                    </svg>

                                    {/* Items */}
                                    <div className="max-w-sm mx-auto grid gap-12 md:gap-x-6 lg:gap-x-12 md:grid-cols-3 items-start md:max-w-none">

                                        {/* 1st item */}
                                        <div className="relative flex flex-col items-center">
                                            {/* Line connecting (1) and (2) */}
                                            <div aria-hidden="true" className="absolute h-px opacity-50 bg-gradient-to-r from-gray-900 via-teal-400 to-gray-900 hidden md:block" style={{ width: 'calc(100% - 48px)', left: 'calc(50% + 48px)', top: '24px' }}>
                                                <div className="absolute inset-0 border-t border-dashed border-gray-900"></div>
                                            </div>
                                            <div className="absolute top-14">Download the App</div>
                                            {/* Number */}
                                            <div className="w-12 h-12 rounded-full  bg-teal-600 bg-opacity-10 flex justify-center items-center  text-teal-400 font-medium mb-8 lg:mb-12">1</div>
                                            {/* Mobile mockup */}
                                            <div className="inline-flex relative justify-center items-center">
                                                <img className="relative max-w-full mx-auto h-auto pointer-events-none" src={SignInScreenPhoto} width="290" height="624" style={{ maxWidth: '100%' }} alt="Sign In screen"/>
                                            </div>
                                        </div>

                                        {/* 2nd item */}
                                        <div className="relative flex flex-col items-center">
                                            {/* Line connecting (2) and (3) */}
                                            <div aria-hidden="true" className="absolute h-px opacity-50 bg-gradient-to-r  from-gray-900 via-teal-400 to-gray-900 hidden md:block" style={{ width: 'calc(100% - 48px)', left: 'calc(50% + 48px)', top: '24px' }}>
                                                <div className="absolute inset-0 border-t border-dashed  border-gray-900"></div>
                                            </div>
                                            <div className="absolute top-14">Sign up</div>
                                            {/* Number */}
                                            <div className="w-12 h-12 rounded-full bg-teal-600 bg-opacity-50 flex justify-center items-center text-teal-400 font-medium mb-8 lg:mb-12">2</div>
                                            {/* Mobile mockup */}
                                            <div className="inline-flex relative justify-center items-center">
                                                <img className="relative max-w-full mx-auto h-auto pointer-events-none" src={SignUpScreenPhoto} width="290" height="624" style={{ maxWidth: '100%' }} alt="Sign In screen"/>
                                            </div>
                                        </div>

                                        {/* 3rd item */}
                                        <div className="relative flex flex-col items-center">
                                            <div className="w-12 h-12 rounded-full bg-teal-600 bg-opacity-90 flex justify-center items-center text-teal-400 font-medium mb-8 lg:mb-12">3</div>
                                            <div className="absolute top-14">Start Looking for jobs</div>
                                            {/* Mobile mockup */}
                                            <div className="inline-flex relative justify-center items-center">
                                                <img className="relative max-w-full mx-auto h-auto pointer-events-none" src={JobSearchScreenPhoto} width="290" height="624" style={{ maxWidth: '100%' }} alt="Sign In screen"/>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </section>


                        {/* Employee FAQ */}
                        <EmployeeFAQ />

                        {/* Footer */}
                        <Footer />
                    {/* </main> */}

                </div>

            </div> 
        </>
    )

}