// @ts-nocheck
import React from "react";
import { CustomNavbar, Footer } from "../../components";

export const DisclaimerScreen  = () => {
      return (
        <>
           <div className="font-inter antialiased bg-dark text-gray-900 dark:bg-gray-900 dark:text-gray-100 tracking-tight">
                <div className="flex flex-col min-h-screen overflow-hidden">
                    <CustomNavbar />
                        <iframe
                            src="https://app.termly.io/policy-viewer/policy.html?policyUUID=0ddbcd2a-b424-429c-ae9c-e42f1f90be38"
                            title="Terms and Conditions"
                            className="w-full h-screen border-none"
                            style={{ border: 'none' }}
                        ></iframe>
                    <Footer/>
                </div>
            </div>
        </>
    );
}