import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'; 

const faqs = [
  {
    question: "How does YouCrew work for Job Seekers?",
    answer: "YouCrew connects job seekers with employers by providing a platform to search for job opportunities, create profiles, and directly apply for positions.",
  },
  {
    question: "Is my personal information secure on YouCrew?",
    answer: "Yes, we prioritize the security and privacy of our users' personal information and follow strict protocols to protect your data.",
  },
  {
    question: "Can I use YouCrew to find short-term or freelance opportunities?",
    answer: "Absolutely! YouCrew offers a variety of job types including short-term, freelance, and full-time positions.",
  },
  {
    question: "How does the direct connection with Employers work?",
    answer: "Employers can view your profile and reach out to you directly through the YouCrew platform, making the hiring process quicker and more efficient.",
  },
  {
    question: "Can I receive reviews from previous Employers on YouCrew?",
    answer: "Yes, YouCrew allows employers to leave reviews on your profile, which can help you build a strong professional reputation.",
  },
  {
    question: "What types of jobs are available on YouCrew?",
    answer: "YouCrew features a wide range of jobs, including jobs in construction like project management, labor, engineering, and more.",
  },
];

export const EmployeeFAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t  border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h1 font-red-hat-display mb-4 text-6xl">
              <span className='text-teal-500 font-bold'>FAQs</span>
            </h2>
            <p className="text-xl text-gray-400">
              Find answers to common questions about how YouCrew can help you as an employee.
            </p>
          </div>

          {/* FAQ Items */}
          <div className="max-w-3xl mx-auto">
            {faqs.map((faq, index) => (
              <div key={index} className="mb-6">
                <button
                  onClick={() => toggleFAQ(index)}
                  className="w-full text-left text-lg font-medium  text-gray-200 bg-gray-800 p-4 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-teal-400 focus-visible:ring-opacity-75"
                >
                  {faq.question} {openIndex === index ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} className='ml-2'/>}
                </button>
                {openIndex === index && (
                  <div className="mt-2 p-4  bg-gray-700 text-gray-300 rounded-lg">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
