import React from "react"
import { CustomNavbar, EmployerFAQ, Footer } from "../../components";
import { motion } from "framer-motion";
import { DownloadAndroidImage, DownloadIosImage, EmployerSearchScreenPhoto, EmployersHeroScreenPhoto, EmployerSignUpScreenPhoto, SignInScreenPhoto } from "../../assets";

export const Employers = () => {
    return (
        <>
           <div className="font-inter antialiased bg-dark  bg-gray-900 text-gray-100 tracking-tight">
                <div className="flex flex-col min-h-screen overflow-hidden">
                    <CustomNavbar />
                    <section className="pt-20"> {/* Added padding to the top */}
                        <div className="max-w-6xl mx-auto px-4 py-4 sm:px-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                <div className="order-1">
                                    <h1
                                        className="h1 text-6xl mb-4 font-bold text-white"
                                        data-aos="fade-down"
                                    >
                                        How Employers <span className='text-teal-500 font-bold'>Benefit?</span>
                                    </h1>
                                    <p className="text-gray-100 mt-4 text-lg">
                                        Need to staff new projects? Fill urgent positions? Temporary or seasonal increases in workload? Need specialised skills? Need Diverse Skills? YouCrew provides employers with a solution that simplifies the employment process,
                                    </p>
                                    <p className="text-gray-200 mt-4 text-lg">
                                        {/* YouCrew provides employers with a solution that simplifies the employment process, offering you a cost-effective and stress-free way to access a wide pool of skills and build your crew. With our platform, you can make choices efficiently, connect directly with candidates, save time, and bypass delays associated with traditional recruitment methods. We eliminate the middleman and utilise technology to make the process super accessible and straightforward. */}
                                    </p>

                                    <ul
                                                className="max-w-sm sm:max-w-md mx-auto md:max-w-none text-gray-300 mt-8 -mb-2"
                                                data-aos="fade-down"
                                                data-aos-delay="450"
                                            >
                                                <li className="flex items-center mb-2">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-teal-400 mr-2 shrink-0"
                                                        viewBox="0 0 12 12"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                                    </svg>
                                                    <span>Quick Set Up</span>
                                                </li>
                                                <li className="flex items-center mb-2">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-teal-400 mr-2 shrink-0"
                                                        viewBox="0 0 12 12"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                                    </svg>
                                                    <span>Comprehensive Candidate Talent Pool</span>
                                                </li>
                                                <li className="flex items-center mb-2">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-teal-400 mr-2 shrink-0"
                                                        viewBox="0 0 12 12"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                                    </svg>
                                                    <span>Free Job Advertising</span>
                                                </li>
                                            </ul>
                                    <div className="flex mt-5 justify-start">
                                        <a className="mr-2" href="https://apps.apple.com/us/app/handyfind/id6469476759">
                                            {/* Download on App Store */}
                                            <img 
                                                className="relative pointer-events-none"
                                                src={DownloadIosImage}
                                                alt="Download on the App Store"
                                            />
                                        </a>
                                        <a className="ml-2"
                                            href="https://play.google.com/store/apps/details?id=com.handyfindapp">
                                            {/* Download on Google Play Store */}
                                            <img 
                                                className="relative pointer-events-none"
                                                src={DownloadAndroidImage}
                                                alt="Get it on Google Play"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="flex justify-end order-2 items-end my-5">
                                    <motion.div
                                        initial={{ x: 200 }}
                                        animate={{ x: 0 }}
                                        transition={{ duration: 0.8 }}
                                        className="relative w-full"
                                    >
                                        <div className="relative">
                                                <svg
                                                    className="absolute items-start inset-10 pointer-events-none -z-1 opacity-40"
                                                    aria-hidden="true"
                                                    width="678"
                                                    height="634"
                                                    viewBox="0 0 678 634"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="240"
                                                        cy="394"
                                                        r="240"
                                                        fill="url(#piphoneill_paint0_radial)"
                                                        fillOpacity=".4"
                                                    />
                                                    <circle
                                                        cx="438"
                                                        cy="240"
                                                        r="240"
                                                        fill="url(#piphoneill_paint1_radial)"
                                                        fillOpacity=".6"
                                                    />
                                                    <defs>
                                                        <radialGradient
                                                            id="piphoneill_paint0_radial"
                                                            cx="0"
                                                            cy="0"
                                                            r="1"
                                                            gradientUnits="userSpaceOnUse"
                                                            gradientTransform="rotate(90 -77 317) scale(189.054)"
                                                        >
                                                            <stop stopColor="#667EEA" />
                                                            <stop offset="1" stopColor="#667EEA" stopOpacity=".01" />
                                                        </radialGradient>
                                                        <radialGradient
                                                            id="piphoneill_paint1_radial"
                                                            cx="0"
                                                            cy="0"
                                                            r="1"
                                                            gradientUnits="userSpaceOnUse"
                                                            gradientTransform="rotate(90 99 339) scale(189.054)"
                                                        >
                                                            <stop stopColor="#9F7AEA" />
                                                            <stop offset="1" stopColor="#9F7AEA" stopOpacity=".01" />
                                                        </radialGradient>
                                                    </defs>
                                                </svg>
                                            <img
                                                className="relative bottom-0 landing-image w-full h-auto"
                                                alt="img"
                                                src={EmployersHeroScreenPhoto}
                                            />
                                            
                                        </div>
                                    </motion.div>
                                </div>

                            </div>
                        </div>
                    </section>

                    {/* Process */}
                     <section className="relative">
                        <div className="max-w-6xl mx-auto px-4 sm:px-6">
                            <div className="py-12 md:py-20 border-t border-gray-800">

                                {/* Section header */}
                                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                                    <h2 className="h1 font-red-hat-display mb-4 text-6xl">
                                        Get Started in <span className='text-teal-500 font-bold'>3 Easy Steps</span>
                                    </h2>
                                    <p className="text-xl  text-gray-400">
                                        Discover how YouCrew simplifies helps you find staff instantly. Our platform ensures you find and fill positions quickly, with efficient job matching and comprehensive profiles—making employment easier and faster for everyone.
                                    </p>
                                </div>

                                {/* Glow illustration */}
                                <svg className="absolute left-1/2 transform -translate-x-1/2 mt-20 lg:mt-40 pointer-events-none -z-1 opacity-20 hidden md:block" aria-hidden="true" width="854" height="509" viewBox="0 0 854 509" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="39.386%" id="iphonesill__a">
                                            <stop stopColor="#667EEA" offset="0%" />
                                            <stop stopColor="#667EEA" stopOpacity="0" offset="100%" />
                                        </radialGradient>
                                        <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="39.386%" id="iphonesill__b">
                                            <stop stopColor="#9F7AEA" offset="0%" />
                                            <stop stopColor="#9F7AEA" stopOpacity="0" offset="100%" />
                                        </radialGradient>
                                    </defs>
                                    <g transform="translate(-64 -64)" fill="none" fillRule="evenodd">
                                        <circle fillOpacity=".64" fill="url(#iphonesill__a)" cx="300" cy="300" r="300" />
                                        <circle fillOpacity=".72" fill="url(#iphonesill__b)" cx="729" cy="384" r="240" />
                                    </g>
                                </svg>

                                {/* Items */}
                                <div className="max-w-sm mx-auto grid gap-12 md:gap-x-6 lg:gap-x-12 md:grid-cols-3 items-start md:max-w-none">

                                    {/* 1st item */}
                                    <div className="relative flex flex-col items-center">
                                        {/* Line connecting (1) and (2) */}
                                        <div aria-hidden="true" className="absolute h-px opacity-50 bg-gradient-to-r  from-gray-900 via-teal-400 to-gray-900 hidden md:block" style={{ width: 'calc(100% - 48px)', left: 'calc(50% + 48px)', top: '24px' }}>
                                            <div className="absolute inset-0 border-t border-dashed border-white "></div>
                                        </div>
                                        {/* Number */}
                                        <div className="w-12 h-12 rounded-full bg-teal-400 bg-opacity-10 flex justify-center items-center text-white font-medium mb-8 lg:mb-12">1</div>
                                        {/* Mobile mockup */}
                                        <div className="inline-flex relative justify-center items-center">
                                            <img className="relative max-w-full mx-auto h-auto pointer-events-none" src={SignInScreenPhoto} width="290" height="624" style={{ maxWidth: '100%' }} alt="Sign In screen"/>
                                        </div>
                                    </div>

                                    {/* 2nd item */}
                                    <div className="relative flex flex-col items-center">
                                        {/* Line connecting (2) and (3) */}
                                        <div aria-hidden="true" className="absolute h-px opacity-50 bg-gradient-to-r from-gray-900 via-teal-400 to-gray-900 hidden md:block" style={{ width: 'calc(100% - 48px)', left: 'calc(50% + 48px)', top: '24px' }}>
                                            <div className="absolute inset-0 border-t border-dashed border-white"></div>
                                        </div>
                                        {/* Number */}
                                        <div className="w-12 h-12 rounded-full bg-teal-400 bg-opacity-50 flex justify-center items-center text-white font-medium mb-8 lg:mb-12">2</div>
                                        {/* Mobile mockup */}
                                        <div className="inline-flex relative justify-center items-center">
                                            <img className="relative max-w-full mx-auto h-auto pointer-events-none" src={EmployerSignUpScreenPhoto} width="290" height="624" style={{ maxWidth: '100%' }} alt="Sign Up screen"/>
                                        </div>
                                    </div>

                                    {/* 3rd item */}
                                    <div className="relative flex flex-col items-center">
                                        <div className="w-12 h-12 rounded-full bg-teal-400 bg-opacity-90 flex justify-center items-center text-white  font-medium mb-8 lg:mb-12">3</div>
                                        {/* Mobile mockup */}
                                        <div className="inline-flex relative justify-center items-center">
                                            <img className="relative max-w-full mx-auto h-auto pointer-events-none" src={EmployerSearchScreenPhoto} width="290" height="624" style={{ maxWidth: '100%' }} alt="Talent pool screen"/>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>

                    {/* faqs */}
                    <EmployerFAQ/>

                    {/* footer */}
                    <Footer/>
                </div>
            </div>

        </>
    );
}